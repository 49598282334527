.aaa {
  backgroundcolor: yellow;
}

.hh {
  height: 100vh;
}

.ff {
  box-sizing: border-box;
  transition: flex 0.2s ease-in, width 0.2s ease-in;
  flex: 0 0 260px;
  width: 260px;
  display: flex;
  flex-direction: column;
  left: 0px;
  position: fixed;
  height: 100vh;
  z-index: 3;
  /*background: rgb(249, 14, 14); */
  /*red*/
  background: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.02) 4px 4px 4px, rgba(0, 0, 0, 0.01) 4px 0px 10px;
}

.gg {
  flex: 1 1 auto;
  width: 100%;
  overflow: auto;
}

.labelSideMenu {
  text-align: left;
}

.appbarStylesLabel {
  color: white;
}

/*.nav-toggle {
  background-color: green !important;
  color: white !important;  
}*/

.appbar ul li a {
  color: white !important;
}

.theMainContent {
  margin-left: 56px;
}
