.page {
  background-color: white;
}

.panelContainer {
  margin-top: 50px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 0px;
}

.withoutBorderRadius {
  border-radius: 0px;
}

.containerLogin {
  padding-top: 20px;
  padding-bottom: 20px;
}

.vvv {
  margin-top: 20px;
}

.rs-icon.fill-color use{
   fill: currentColor;
}

.show-grid {
  background-color: yellow;
}

.logoImageResp {
  
}